<template>
  <article-page :menu="menu">
    <div class="training-content" id="section-history-table">
      <div class="_subtitle">
        Данные по истории уведомлений
      </div>
      <span>
        Все пользователи могут просматривать страницу
        <accessed-link :name="Tabs.NotificationHistory" target="_blank"> История уведомлений</accessed-link>.
        Она содержит всю историю email рассылок. Для удобства, Вам доступна сортировка по дате отправки, а также фильтр по виду уведомления.
      </span>
      <span>
        Всем пользователям, независимо от тарифа, доступна информация о следующих видах уведомлений:
      </span>
      <ul>
        <li>Регистрация;</li>
        <li>Приглашение в компанию;</li>
        <li>Смена быстрой ссылки;</li>
        <li>Сброс пароля;</li>
        <li>Информационные письма;</li>
      </ul>
      <span>
        В зависимости от тарифа, Вам также могут быть предусмотрены:
      </span>
      <ul>
        <li>Рассылка по шаблонам - если в тарифе предусмотрена рассылка новых тендеров на email;</li>
        <li>Рассылка по событиям (подача заявок/торги/контракты) - если в тарифе предусмотрена рассылка о регламентных сроках активных закупок;</li>
      </ul>
      <span>
        Подробнее о настройке рассылок по шаблонам Вы можете прочитать в статье <router-link class="link" :to="{ name: Tabs.Training.Templates }">Создание и работа с шаблонами</router-link>.
        Про рассылку по событиям упоминается в статьях
        <router-link class="link" :to="{ name: Tabs.Training.Apps }">Работа с заявками</router-link>
        и <router-link class="link" :to="{ name: Tabs.Training.Contracts }">Работа с контрактами</router-link>.
      </span>
      <article-image :src="images[0].src" :alt="images[0].alt" @click="openViewer(images, 0)"/>
    </div>
  </article-page>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useImageViewer } from "@/use/other/useImageViewer";
import ArticlePage from "@/components/pages/training/ArticlePage.vue";
import ArticleImage from "@/components/pages/training/ArticleImage.vue";
import AccessedLink from "@/components/ui/links/AccessedLink.vue";
import { Tabs } from "@/router/tabs";

export default defineComponent({
  name: "NotificationHistoryArticle",
  components: {
    AccessedLink,
    ArticlePage,
    ArticleImage,
  },
  setup() {

    const menu = [
      { id: 'section-history-table', title: 'Таблица рассылок' },
    ]

    const images = [
      { src: 'orders/notifications-history.png', alt: 'История уведомлений' },
    ]

    const { openViewer } = useImageViewer();

    return {
      Tabs,
      menu,
      images,
      openViewer,
    }
  }
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/pages/training';
</style>
